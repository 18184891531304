const questions = [
  {
    text: "Choose the correct sentence:",
    options: [
      "I have been working here since three years",
      "I have been working here for three years",
      "I am working here since three years",
      "I work here since three years"
    ],
    correct: "I have been working here for three years"
  },
  {
    text: "What's the correct way to form a question in the past tense?",
    options: [
      "Did you went to the store?",
      "Did you go to the store?",
      "Have you went to the store?",
      "Do you went to the store?"
    ],
    correct: "Did you go to the store?"
  },
  {
    text: "Select the sentence with correct subject-verb agreement:",
    options: [
      "The team are playing well",
      "The team is playing well",
      "The team were playing well",
      "The team have playing well"
    ],
    correct: "The team is playing well"
  },
  {
    text: "Choose the correct conditional sentence:",
    options: [
      "If I would have known, I would have told you",
      "If I had known, I would had told you",
      "If I had known, I would have told you",
      "If I would know, I would tell you"
    ],
    correct: "If I had known, I would have told you"
  },
  {
    text: "Which sentence uses the correct preposition?",
    options: [
      "I'm interested on learning English",
      "I'm interested for learning English",
      "I'm interested to learning English",
      "I'm interested in learning English"
    ],
    correct: "I'm interested in learning English"
  },
  {
    text: "Choose the correct reported speech:",
    options: [
      "He said that he is going to the party",
      "He said that he was going to the party",
      "He said that he goes to the party",
      "He said that he had go to the party"
    ],
    correct: "He said that he was going to the party"
  },
  {
    text: "Which sentence demonstrates correct use of articles?",
    options: [
      "I had a dinner at the restaurant in downtown",
      "I had dinner at a restaurant in the downtown",
      "I had a dinner at a restaurant in the downtown",
      "I had dinner at a restaurant downtown"
    ],
    correct: "I had dinner at a restaurant downtown"
  },
  {
    text: "Select the sentence with the correct comparative form:",
    options: [
      "This book is more better than that one",
      "This book is better than that one",
      "This book is much better than that one",
      "This book is more good than that one"
    ],
    correct: "This book is better than that one"
  },
  {
    text: "Which sentence uses the perfect continuous tense correctly?",
    options: [
      "I am studying English since 2020",
      "I have been studying English since 2020",
      "I have studying English since 2020",
      "I was studying English since 2020"
    ],
    correct: "I have been studying English since 2020"
  },
  {
    text: "Choose the sentence with correct passive voice:",
    options: [
      "The letter written by John",
      "The letter was wrote by John",
      "The letter was written by John",
      "The letter is write by John"
    ],
    correct: "The letter was written by John"
  }
];

export default questions; 