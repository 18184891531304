import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CALENDLY_URL = "https://calendly.com/amit_patel/book-a-call-with-native-english-speaker";

const AssessmentResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { score, total } = location.state || { score: 0, total: 5 };
  const percentage = Math.round((score / total) * 100);

  const getLevelAndMessage = (percentage) => {
    if (percentage >= 80) {
      return {
        level: "Advanced",
        message: "You have a strong command of English! Let's work on perfecting your skills."
      };
    } else if (percentage >= 60) {
      return {
        level: "Intermediate",
        message: "You have a good foundation. With focused practice, you can reach advanced level."
      };
    } else {
      return {
        level: "Beginner to Pre-Intermediate",
        message: "You're at the start of your journey. We can help you build strong fundamentals."
      };
    }
  };

  const { level, message } = getLevelAndMessage(percentage);

  const handleBookSession = () => {
    window.open(CALENDLY_URL, "_blank", "noreferrer");
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card border-0 shadow-sm text-center">
            <div className="card-body p-5">
              <h2 className="mb-4">Your English Assessment Results</h2>
              
              <div className="display-4 text-primary mb-3">
                {percentage}%
              </div>
              
              <h3 className="mb-3">Level: {level}</h3>
              <p className="lead mb-4">{message}</p>
              
              <div className="score-details mb-4">
                <p className="mb-2">You got {score} out of {total} questions correct</p>
              </div>

              <div className="cta-section">
                <p className="mb-4">
                  Ready to improve your English? Schedule a free consultation with our expert 
                  native speakers to create your personalized learning plan.
                </p>
                
                <button 
                  onClick={handleBookSession}
                  className="btn btn-primary btn-lg mb-3"
                >
                  Schedule Free Consultation
                </button>
                
                <div>
                  <button 
                    onClick={() => navigate('/')}
                    className="btn btn-link"
                  >
                    Return to Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentResults; 