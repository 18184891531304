import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import questions from './questions';
import { logFirebaseEvent, logFirebasePageName } from "../../utils/analytics";

const CALENDLY_URL = "https://calendly.com/amit_patel/book-a-call-with-native-english-speaker";

const EnglishAssessment = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    logFirebasePageName("PAGE_VIEW_ENGLISH_ASSESSMENT");
  }, []);

  const handleAnswer = (selectedOption) => {
    logFirebaseEvent("ANSWER_SELECTED_ASSESSMENT", {
      question_number: currentQuestion + 1,
      selected_answer: selectedOption
    });

    const newAnswers = [...answers, selectedOption];
    setAnswers(newAnswers);

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const finalScore = newAnswers.filter((answer, index) => 
        answer === questions[index].correct
      ).length;

      logFirebaseEvent("ASSESSMENT_COMPLETED", {
        total_questions: questions.length,
        correct_answers: finalScore
      });
      
      navigate('/english-assessment/results', { 
        state: { 
          score: finalScore,
          total: questions.length 
        } 
      });
    }
  };

  const handleBookSession = () => {
    window.open(CALENDLY_URL, "_blank", "noreferrer");
  };

  const question = questions[currentQuestion];

  return (
    <div className="min-vh-100 bg-light py-5">
      <div className="container">
        {/* Header Section */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-8 text-center">
            <h1 className="display-4 fw-bold mb-3" style={{ color: '#2C3E50' }}>
              English Proficiency Assessment
            </h1>
            <p className="lead mb-0" style={{ color: '#666' }}>
              Test your English skills with our comprehensive assessment to get a personalized learning plan.
            </p>
          </div>
        </div>

        {/* Question Section */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-7">
            <div className="card border-0 shadow-sm rounded-3">
              <div className="card-body p-4 p-lg-5">
                {/* Progress Bar */}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="badge bg-primary px-3 py-2 rounded-pill">
                    Question {currentQuestion + 1} of {questions.length}
                  </span>
                  <div className="progress flex-grow-1 ms-3" style={{ height: "6px" }}>
                    <div 
                      className="progress-bar bg-primary" 
                      role="progressbar" 
                      style={{ 
                        width: `${(currentQuestion / questions.length) * 100}%`,
                        transition: 'width 0.5s ease-in-out'
                      }}
                    />
                  </div>
                </div>

                {/* Question */}
                <h2 className="h4 mb-4 text-center fw-bold" style={{ color: '#2C3E50' }}>
                  {question.text}
                </h2>
                
                {/* Options */}
                <div className="d-grid gap-3">
                  {question.options.map((option, index) => (
                    <button
                      key={index}
                      className="btn btn-outline-primary text-start p-3 rounded-3 position-relative answer-option"
                      onClick={() => handleAnswer(option)}
                      style={{ 
                        transition: 'all 0.2s ease-in-out',
                        borderWidth: '2px'
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className="fw-bold me-4" style={{ minWidth: '28px' }}>
                          {String.fromCharCode(65 + index)}.
                        </span>
                        <span>{option}</span>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Video Section */}
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="card border-0 shadow-sm rounded-3 overflow-hidden">
              <div className="card-body p-4 p-lg-5">
                <h3 className="h4 text-center mb-4 fw-bold" style={{ color: '#2C3E50' }}>
                  Improve Your English with Native Speakers
                </h3>
                <div className="ratio ratio-16x9 mb-4 rounded-3 overflow-hidden shadow-sm">
                  <iframe
                    src="https://www.youtube.com/embed/3tJVHKAaGBI?autoplay=0"
                    title="Learn English with Native Speakers"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="text-center">
                  <button 
                    onClick={handleBookSession}
                    className="btn btn-primary btn-lg px-5 py-3 rounded-pill shadow-sm consultation-btn"
                    style={{ transition: 'all 0.2s ease-in-out' }}
                  >
                    Schedule Free Consultation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          .answer-option:hover {
            transform: translateY(-2px);
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
          }
          
          .consultation-btn:hover {
            transform: translateY(-2px);
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
          }
        `}
      </style>
    </div>
  );
};

export default EnglishAssessment; 