import React, { useState, useEffect } from 'react';
import { logFirebaseEvent, logFirebasePageName } from "../../utils/analytics";

const TOEFLListeningTest = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    logFirebasePageName("PAGE_VIEW_TOEFL_LISTENING_TEST");
  }, []);

  const sampleQuestion = {
    audioUrl: "https://example.com/sample-audio.mp3",
    question: "What is the main topic of the lecture?",
    options: [
      "Marine Biology",
      "Environmental Science",
      "Evolutionary Biology",
      "Ecological Systems"
    ],
    correctAnswer: 0
  };

  const handleStartTest = () => {
    logFirebaseEvent("START_TOEFL_LISTENING_TEST");
    setIsPlaying(true);
  };

  return (
    <div className="container-fluid min-vh-100" style={{ 
      backgroundColor: '#1e1e2f',
      color: '#ffffff',
      padding: '4rem 2rem'
    }}>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card border-0 shadow-lg" style={{ backgroundColor: '#242645' }}>
            <div className="card-body p-4">
              <h1 className="text-center mb-4">TOEFL Listening Practice Test</h1>
              
              {!isPlaying ? (
                <div className="text-center">
                  <h2 className="h4 mb-4">Instructions</h2>
                  <ul className="text-start list-unstyled">
                    <li>✓ Make sure your audio is working</li>
                    <li>✓ You will hear each audio clip only once</li>
                    <li>✓ Take notes while listening</li>
                    <li>✓ Answer questions based on what you hear</li>
                  </ul>
                  <button 
                    className="btn btn-primary btn-lg mt-4"
                    onClick={handleStartTest}
                  >
                    Start Test
                  </button>
                </div>
              ) : (
                <div>
                  <div className="audio-player mb-4">
                    {/* Audio player component will go here */}
                    <audio controls className="w-100">
                      <source src={sampleQuestion.audioUrl} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>

                  <div className="question-section">
                    <h3 className="h5 mb-3">{sampleQuestion.question}</h3>
                    <div className="options">
                      {sampleQuestion.options.map((option, index) => (
                        <div key={index} className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="questionOption"
                            id={`option${index}`}
                          />
                          <label className="form-check-label" htmlFor={`option${index}`}>
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TOEFLListeningTest; 